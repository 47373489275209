import { useEffect } from "react"
import { navigate } from "gatsby"

/**
 * For now the 404 page just redirects to index
 */
export default function FourOhFour() {
  useEffect(() => {
    navigate("/")
  }, [])

  return null
}
